import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopSection from "../components/topSection";
import {graphql} from "gatsby";

const AboutUs = ({data}) => (
    <Layout>
        <SEO title="Page two"/>
        <TopSection title="About Us" image={data.desktop.childImageSharp.fluid}/>
        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h2>Our Story</h2>
                        <p>
                            Lorem ipsum dolor sit amet Lorem Ipsum. Proin grav dalorem qucis bibendum aucto Duis sed
                            odio sit amet nibh vulputate cursus a sit aet mauris. Morbi accumsan ipsum velit. Nam
                            nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat
                            auctor eu in elit. Class aptent taciti sociosqu ad litora torquent peronubia auctor
                            ornare.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <h2>&nbsp;</h2>
                        <p>
                            Lorem ipsum dolor sit amet Lorem Ipsum. Proin grav dalorem quis bibendum aucto Duis sed
                            odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam
                            nec tellus a odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat
                            auctor eun elit. Class aptent taciti sociosqu ad litora torquent peronuia equatauctor
                            eun.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <h3>Press</h3>
                        <h4>Peter Saler</h4>
                        <p>email</p>
                        <p>phone</p>
                    </div>
                    <div className="col-md-3">
                        <h3>Press</h3>
                        <h4>Peter Saler</h4>
                        <p>email</p>
                        <p>phone</p>
                    </div>
                    <div className="col-md-3">
                        <h3>Press</h3>
                        <h4>Peter Saler</h4>
                        <p>email</p>
                        <p>phone</p>
                    </div>
                    <div className="col-md-3">
                        <h3>Press</h3>
                        <h4>Peter Saler</h4>
                        <p>email</p>
                        <p>phone</p>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)

export const aboutBackgroundImage = graphql`
    query {
        desktop: file(relativePath: { eq: "about.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

export default AboutUs
